const routerAjax = (link, historyChange) => {
    $.ajax({
        url: '' + link,
        dataType: 'html',
        error: function(xhr, status, error) {
            // TODO: create error.log for messed up ajax calls
            // Also: redirect user somewhere as a fallback
            console.log(xhr.responseText);
        },
        success: async (html) => {
            const history = '';
            const ajax_data = await $("#module-wrapper", html).html();
            const module    = await $(ajax_data).filter('#module');
            // Inject ajax html into app
            $('#app').html(ajax_data);
            // Create a new event for the module
            const routerModule = new Event(module[0].className);
            // Check if user clicked back button
            if(historyChange === true) {
                // Do nothing
            }
            // Otherwise we add the link to browser history
            else {
                // Set new history (for back button)
                window.history.pushState(null, routerModule, link);
            }
            // fire event based on module classname
            document.dispatchEvent(routerModule);
            // When module script is done...
            // rebind the router
            document.dispatchEvent(new Event('router'));
        }
    });
};

export default routerAjax;
