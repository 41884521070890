import io              from 'socket.io-client';
import eventDispatcher from './helpers/event-dispatcher';

const app = {
    // Application Constructor
    initialize: function() {
        // Do a high level dom check for module name
        const moduleDomElement = document.getElementById('module');
        const moduleName       = moduleDomElement.getAttribute('class');
        // Create and dispatch the events
        const readyEvent       = eventDispatcher(moduleName);
        const router           = eventDispatcher('router');
        const mainNavigation   = eventDispatcher('main-navigation');

        // Connect socket to server
        var socket = io('http://ontw.rosevisagie2018.dev.ontwikkelversie.nl:9000/');

        // Language switcher and site id functionality
        socket.on('languageCode', function(data) {
            $('body').addClass(data.languageCode);
            localStorage.setItem('site-language', data.token);
        });
    }
};

export default app;
