import Module          from './../../../system/classes/Module';
const  Masonry         = require('masonry-layout');
const  imagesLoaded    = require('imagesloaded');
import heroHeader      from './../../../components/headers/hero-header/js/hero-header';

// ========================================================
// ========================================================

// Every module needs a unique function
// this gets fired on eventListener from router
const contentPage = () => {

    // These functions will be fired for this specific module
    const moduleFunctions = async () => {
        try {
            // Do module stuff
            heroHeader();

            // Select our portfolio list dom element
            const portfolio = document.querySelector('#portfolio-list');
            // Wait until images are loaded within portfolio
            if(portfolio) {
                imagesLoaded( portfolio, function() {
                    // Once images are loaded, init Masonry.js for the grid
                    const msnry = new Masonry( portfolio, {
                        // set itemSelector so .grid-sizer is not used in layout
                        itemSelector: '.portfolio-list-item',
                        // use element for option
                        columnWidth: '.grid-sizer',
                        percentPosition: true
                    });
                });            
            }
        }
        catch(err) {
            console.log(err);
        }

        try {
            // Tooltip hovers
            $('[data-toggle="tooltip"]').tooltip();
            // Contact form
            var frm = $('#contact');
            frm.submit(function (e) {
                e.preventDefault();
                var required = true;                                            
                $(".required").each(function( index, value ) {
                    if($(this).val() == '') {
                        required = false;
                        $(this).parent().addClass("error");
                    } else {
                        $(this).parent().removeClass("error");
                    }
                });
                
                if(required) {
                    // Show spinner in form button
                    frm.find('button').html('<i class="fas fa-spinner fa-spin"></i>');
                    // Capture form data
                    var frmData = frm.serializeArray();
                    // Execute recaptcha service to generate token
                    grecaptcha.execute('6Leb238UAAAAADw3oyjxwFQh3PV9H5M0gwMnigqi', {action: 'contact_form'}).then(function(token) {
                        // Verify the recaptcha token & save form data
                        $.ajax({
                            type: 'POST',
                            url: '/recaptcha',
                            data: {
                                token: token,
                                form_data: JSON.stringify(frmData)
                            },
                            success: function(recaptcha) {
                                const recaptchaRes = JSON.parse(recaptcha);
                                if(recaptchaRes.error) {
                                    // User wasn't validated!
                                    frm.find('button').html('<i class="fal fa-times"></i>');
                                }
                                else {
                                    // User is verified, send form
                                    //frm.find('button').html('<i class="fal fa-check"></i>');
                                    $("#contact").html( '<h1>' +recaptchaRes.response.subtitle1  + '</h1>').append(recaptchaRes.response.intro1);
                                    
                                }
                            },
                            error: function(err) {
                                console.log('An error occurred during recaptcha validation.');
                                console.log(err);
                            },
                        });
                    });
                }
            });
        }
        catch(err) {
            console.log(err);
        }
        
    }

    // ====================================================
    // Create a new Module class
    // ====================================================

    /**
     * @param {string}      - moduleName        // Name of the module, gets send to the router
     * @param {function}    - moduleFunctions   // All custom functionality this particular module requires.
     * @method {function}   - .load();          // Tells module class to start executing module functions
     */
    const contentModule = new Module('content-page', moduleFunctions);
    // Load the module
    contentModule.load();
}

// ========================================================
// ========================================================

// Start listening for router events
document.addEventListener('content-page', contentPage);
