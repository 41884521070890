// Polyfill for async functions
import 'babel-polyfill';
// jQuery (As global objects)
window.$ = window.jQuery = require("jquery");
// FontAwesome 5
import fontawesome       from '@fortawesome/fontawesome-pro/js/all';
// FancyBox 3
const fancybox           = require("@fancyapps/fancybox");
// Bootstrap 4
import 'bootstrap';
// Slick Slider (Carousel)
import 'slick-carousel';

// ================================================================================
// ================================================================================

// System dependencies
import app            from './system/app';
import router         from './system/router/router';
import page_loaded    from './system/router/page-loaded';

// Modules
import homepage       from './modules/homepage/js/homepage';
import contentPage    from './modules/content-page/js/content-page';
import contactPage    from './modules/contact-page/js/contact';

// Components
import mainNavigation from './components/navigation/js/main-navigation';

// System
import pageNotFound   from './modules/404/js/page-not-found';

// On window load, initialize app
window.onload=function(){
    app.initialize();
    mainNavigation();
};
