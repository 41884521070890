import eventDispatcher from './../helpers/event-dispatcher';

class Module {
    /**
     * @param  {string}     - moduleName        // Name of the module, gets send to the router
     * @param  {function}   - moduleFunctions   // All custom functionality this particular module requires.
     * @method {function}   - .load();          // Tells module class to start executing module functions
     */
    constructor(moduleName, moduleFunctions) {
        this._moduleDOM        = document.getElementById('module');
        this._moduleName       = moduleName;
        this._moduleFunctions  = moduleFunctions;
    }

    load() {
        // Check if the param 'moduleName' matches the #module DOM element's class
        // <div id="module" class="should contain this._moduleName">
        if(this._moduleDOM.classList.contains(this._moduleName)) {
            // Do the actual module functionality
            this._moduleFunctions();
            // When we're done, fire our page-loaded event
            eventDispatcher('page-loaded');
        }
        else {
            console.log("class Module :: moduleName mismatch. Please make sure you're loading the correct module.")
        }
    }

}

export default Module;
