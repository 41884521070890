import routerAnimations     from './router-animations';
import routerAjax           from './router-ajax';

const router = () => {
    // We disable all normal href functionality if it has the class 'link'
    $('a.link').off('click').on("click", function(e) {
        e.preventDefault();
        const link = $(this).attr('data-link');
        // If data attribute exists, do the ajax call to that url
        if(link) {
            // Do page animations for loading etc...
            routerAnimations();
            // Do the ajax call to get the data from data-link attr url
            setTimeout(function() {
                routerAjax(link, false);
            }, 500);
        }
    });
}

// Back button functionality
window.addEventListener("popstate", function(e) {
    // We need to check if the event trigger is fancybox
    const eventTriggerElement = String(e.explicitOriginalTarget.className);
    if(eventTriggerElement.includes('fancybox')) {
        // Do nothing. Fancybox (vendor) uses popstate as well to hash the url to target image group
    }
    else {
        const link = location.pathname;
        // Do page animations for loading etc...
        routerAnimations();
        // Do the ajax call to get the data from history location.pathname
        setTimeout(function() {
            routerAjax(link, true);
        }, 500);
    }
});

// After completing a module script, this router event will be triggered
document.addEventListener('router', router);
