const heroHeader = () => {

    $(window).scroll(function() {
        var scrollTop = $(this).scrollTop();

        $('#hero-header .hero-header-content').css({
            opacity: function() {
                var height = 400;
                return 0 + (height - scrollTop) / height;
            }
        });
    });
};

export default heroHeader;
