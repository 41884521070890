// NPM Dependencies (packages)
import eventDispatcher from './../../../system/helpers/event-dispatcher';

// ========================================================
// ========================================================

const loadPageNotFound = async () => {
    try {
        // Extra check to see if the function should indeed be fired
        if($('#module').hasClass('page-not-found')) {
            // Do module stuff
            //
            // Done, remove page loader
            eventDispatcher('page-loaded');
        }
    }
    catch(err) {
        console.log(err);
    }
}

// ========================================================
// ========================================================

document.addEventListener('page-not-found', loadPageNotFound);
