// Router animations for page loading etc.
const routerAnimations = () => {
    // Show page loader
    $('#page-loader').addClass('loading');
    // Hide #app & footer
    $('#app').removeClass('loaded');
    $('#footer').removeClass('loaded');
    // Hide mobile navigation
    $('#main-navigation-mobile').removeClass('visible');
    $('#main-navigation-mobile ul').removeClass('visible');
    $('#main-navigation-mobile-overlay').removeClass('visible');
    $('#hamburger-icon').removeClass('active');
    $('#main-navigation-mobile .navigation-breadcrumbs').empty();
    $('#main-navigation-mobile .navigation-breadcrumbs').removeClass('visible');
    // Wait for the duration of #app css transition, in this case 0.5s
    setTimeout(function(){
        // Scroll to top of new page
        $(window).scrollTop(0);
    }, 500);
};

export default routerAnimations;
