import Module          from './../../../system/classes/Module';
const  Masonry         = require('masonry-layout');
const  imagesLoaded    = require('imagesloaded');
import heroHeader      from './../../../components/headers/hero-header/js/hero-header';

// ========================================================
// ========================================================

// Every module needs a unique function
// this gets fired on eventListener from router
const homepage = () => {

    // These functions will be fired for this specific module
    const moduleFunctions = async () => {
        try {
            // Init the hero header
            heroHeader();
            
            // Select our portfolio list dom element
            const portfolio = document.querySelector('#portfolio-list');
            // Wait until images are loaded within portfolio
            imagesLoaded( portfolio, function() {
                // Once images are loaded, init Masonry.js for the grid
                const msnry = new Masonry( portfolio, {
                    // set itemSelector so .grid-sizer is not used in layout
                    itemSelector: '.portfolio-list-item',
                    // use element for option
                    columnWidth: '.grid-sizer',
                    percentPosition: true
                });
            });
        }
        catch(err) {
            console.log(err);
        }
    }

    // ====================================================
    // Create a new Module class
    // ====================================================

    /**
     * @param {string}      - moduleName        // Name of the module, gets send to the router
     * @param {function}    - moduleFunctions   // All custom functionality this particular module requires.
     * @method {function}   - .load();          // Tells module class to start executing module functions
     */
    const homepageModule = new Module('homepage', moduleFunctions);
    // Load the module
    homepageModule.load();
}

// ========================================================
// ========================================================

// Start listening for router events
document.addEventListener('homepage', homepage);
