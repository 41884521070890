const mainNavigation = () => {
    // Init bootstrap 4 popover
    $('[data-toggle="popover"]').popover();
    // Check for list items with dropdowns
    $('#main-navigation li').each(function(){
        if($(this).find('ul.submenu').length > 0) {
            $(this).addClass('has-submenu');
            $(this).children('a').append('<i class="fal fa-angle-down"></i>');
        }
    });

    // Show nav on hamburger click
    $('#main-navigation #hamburger-icon-wrapper').off('click').on('click', function(e) {
        $(this).children('#hamburger-icon').toggleClass('active');
        $('#main-navigation-mobile').toggleClass('visible');
        $('#main-navigation-mobile ul').removeClass('visible');
        $('#main-navigation-mobile-overlay').toggleClass('visible');
        $('#main-navigation-mobile .navigation-breadcrumbs').empty();
        $('#main-navigation-mobile .navigation-breadcrumbs').removeClass('visible');
    });
    // Hide nav when clicking on overlay div
    $('#main-navigation-mobile-overlay').off('click').on('click', function(e) {
        $(this).toggleClass('visible');
        $('#main-navigation-mobile ul').removeClass('visible');
        $('#main-navigation-mobile').toggleClass('visible');
        $('#hamburger-icon').toggleClass('active');
        $('#main-navigation-mobile .navigation-breadcrumbs').empty();
        $('#main-navigation-mobile .navigation-breadcrumbs').removeClass('visible');
    });

    // Handle multi-level ul navigation
    $('#main-navigation-mobile .show-dropdown').off('click').on('click', function(e) {
        $(this).closest('li').children('ul').toggleClass('visible');
        $('#main-navigation-mobile .navigation-breadcrumbs').addClass('visible');
        const breadcrumbDepth = $(this).data('depth');
        const breadcrumbText = $(this).closest('li').children('a').children('span').text();
        const addBreadcrumb = $('<div class="crumb" data-depth="'+breadcrumbDepth+'">'+breadcrumbText+'</div>');
        $('#main-navigation-mobile .navigation-breadcrumbs').append(addBreadcrumb);
        breadcrumbBack();
        recalcBreadcrumbsHeight();
    });

    // Handle breadcrumbs functionality
    const breadcrumbBack = () => {
        $('#main-navigation-mobile .crumb').off('click').on('click', function(e) {
            const breadcrumbDepth = $(this).data('depth');
            $('#main-navigation-mobile ul[data-depth="'+breadcrumbDepth+'"]').removeClass('visible');
            $('#main-navigation-mobile ul[data-depth="'+breadcrumbDepth+'"] ul').removeClass('visible');
            // Remove all crumbs that are BEHIND the clicked crumb
            $(this).remove();
            $('#main-navigation-mobile .navigation-breadcrumbs .crumb').each(function() {
                if($(this).data('depth') > breadcrumbDepth) {
                    $(this).remove();
                }
            });
            // Hide breadcrumbs if we clicked home
            if(breadcrumbDepth <= 2) {
                $('#main-navigation-mobile .navigation-breadcrumbs').removeClass('visible');
            }
            recalcBreadcrumbsHeight();
        });
    };
    breadcrumbBack();

    // Recalculate breadcrumbs height depending on it's content
    const recalcBreadcrumbsHeight = () => {
        const breadcrumbsHeight = $('#main-navigation-mobile .navigation-breadcrumbs').outerHeight();
        const navigationHeight = $('#main-navigation').outerHeight();
        const recalculatedValue = breadcrumbsHeight + navigationHeight;
        $('#main-navigation-mobile ul.main-menu-mobile li ul').css('height', 'calc(100% - '+recalculatedValue+'px)');
        $('#main-navigation-mobile ul.main-menu-mobile li ul').css('top', ''+recalculatedValue+'px');
    };
};

export default mainNavigation;
