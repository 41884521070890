import Module          from './../../../system/classes/Module';

// ========================================================
// ========================================================

// Every module needs a unique function
// this gets fired on eventListener from router
const contact = () => {

    console.log('aaa');
    // These functions will be fired for this specific module
    const moduleFunctions = async () => {
        try {
            // Tooltip hovers
            $('[data-toggle="tooltip"]').tooltip();
            // Contact form
            var frm = $('#contact');
            frm.submit(function (e) {
                console.log('aa23232a');
                e.preventDefault();
                // Show spinner in form button
                frm.find('button').html('<i class="fas fa-spinner fa-spin"></i>');
                // Capture form data
                var frmData = frm.serializeArray();
                // Execute recaptcha service to generate token
                grecaptcha.execute('6Leb238UAAAAADw3oyjxwFQh3PV9H5M0gwMnigqi', {action: 'contact_form'}).then(function(token) {
                    // Verify the recaptcha token & save form data
                    console.log('33');
                    $.ajax({
                        type: 'POST',
                        url: '/recaptcha',
                        data: {
                            token: token,
                            form_data: JSON.stringify(frmData)
                        },
                        success: function(recaptcha) {
                            const recaptchaRes = JSON.parse(recaptcha);
                            if(recaptchaRes.error) {
                                // User wasn't validated!
                                frm.find('button').html('<i class="fal fa-times"></i>');
                            }
                            else {
                                // User is verified, send form
                                frm.find('button').html('<i class="fal fa-check"></i>');
                            }
                        },
                        error: function(err) {
                            console.log('An error occurred during recaptcha validation.');
                            console.log(err);
                        },
                    });
                });
            });
        }
        catch(err) {
            console.log(err);
        }
    }

    // ====================================================
    // Create a new Module class
    // ====================================================

    /**
     * @param {string}      - moduleName        // Name of the module, gets send to the router
     * @param {function}    - moduleFunctions   // All custom functionality this particular module requires.
     * @method {function}   - .load();          // Tells module class to start executing module functions
     */
    const contactModule = new Module('contact-page', moduleFunctions);
    // Load the module
    contactModule.load();
}

// ========================================================
// ========================================================

// Start listening for router events
document.addEventListener('contact-page', contact);
