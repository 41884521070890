const eventDispatcher = (eventName) => {
    // If Event function exists (All normal browsers)
    if(typeof(Event) === 'function') {
        const newEvent = new Event(eventName);
        document.dispatchEvent(newEvent);
    }
    // IE fix
    else {
        const event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
        document.dispatchEvent(event);
    }
};

export default eventDispatcher;
